<template>
  <div class="checkProShowCanvas">
    <div :class="[curIndex == -1 && 'active']" class="preview-wrapper" @click="documentClick">
      <div ref="preview" :class="['preview', autoPreview && 'auto-preview']" v-loading="loading">
        <el-image :src="randomDocSrc" @load="load" @error="imgLoadingClose"/>
        <div
          @click.stop="groupClick(groupInfo, index)"
          :key="index"
          :class="['group', index == curIndex && 'active']"
          :style="coodStyle"
          v-for="({ groupInfo, ...coodStyle }, index) in coods"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { resizeMixin } from '@/mixins'
import { conversionCoord, getUUID } from '@/utils'
import { getKnifeReference, baseDetail } from '@/api/product/checkProdApi'
import { MAKER } from '@/utils/constant'
const BOXHEIGHT = 400
export default {
  mixins: [resizeMixin],
  props: {
    sup_this: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      fLoading: false, //父亲组件数据是否渲染完成
      loading: true,
      docSrc: '',
      psdInfo: {},
      coods: [],
      info: {},
      curGroupLayer: {},
      curIndex: -2,
      groupNodes: [],
      boxOption: {},

      process: {},

      isPicLoaded: false, //图片加载完成
      isDataLoaded: false //数据加载完成
    }
  },

  mounted() {
    this.initData()
  },

  computed: {
    autoPreview() {
      return !!this.boxOption.height
    },

    randomDocSrc({ docSrc }) {
      if (!docSrc) return ''
      return docSrc + '?uid=' + getUUID()
    }
  },

  watch: {
    isPicLoaded() {
      this.firstLoadingEnd()
    },

    isDataLoaded() {
      this.firstLoadingEnd()
    }
  },

  methods: {
    onresize() {
      this.firstLoadingEnd()
    },

    load() {
      this.isPicLoaded = true
    },

    async firstLoadingEnd() {
      if (!this.isPicLoaded || !this.isDataLoaded) return
      await this.$nextTick()
      const { referSizePsdConfig, referSizeLayerGroupList } = this.psdInfo
      const coods = this.getCoodsToCreateDom(referSizePsdConfig, referSizeLayerGroupList)
      this.coods = this.setZIndex(coods)
      this.documentClick()
      this.imgLoadingClose()
    },

    imgLoadingClose() {
      if (this.process.request) {
        this.loading = false
      }
    },

    initDocSrc() {
      this.fLoading = true
      if (this.info) {
        this.docSrc = this.info.checkImagePath
        if (!this.docSrc) {
          this.loading = false
        }
      }
    },

    groupClick(groupInfo, index) {
      if (this.curIndex == index) return
      this.curIndex = index
      try {
        const groups = this.info.sizePsdGroupCheckList
        if (!groups.length) return
        const { order: gOrder } = groupInfo
        const info = groups.find(({ order }) => order === gOrder)
        this.$emit('changePartProShowPic', { info, count: groups.length })
      } catch (err) {
        console.log(err)
      }
    },

    documentClick() {
      if (this.curIndex == -1) return
      this.curIndex = -1
      try {
        if (!this.info) return
        const groups = this.info.sizePsdGroupCheckList
        if (!groups.length) return
        this.$emit('changePartProShowPic', { info: groups, count: groups.length })
      } catch (err) {
        console.log(err)
      }
    },

    setZIndex(coods = []) {
      for (let i = 0; i < coods.length; i++) {
        let zIndex = 0
        const { height, width } = coods[i]
        for (let j = 0; j < coods.length; j++) {
          const { height: h, width: w } = coods[j]
          if (parseFloat(height) * parseFloat(width) <= parseFloat(h) * parseFloat(w)) {
            zIndex++
          }
        }
        coods[i].zIndex = zIndex
      }
      return coods
    },

    async baseSizeInfo() {
      const id = Number(this.$route.query.id)
      const data = {
        id
      }
      const { code, detail } = await baseDetail(data)
      if ($SUC({ code })) {
        detail.referSizePsdConfig = {
          ...detail.referSizePsdConfig,
          width: detail.referSizePsdConfig.imageWidth,
          height: detail.referSizePsdConfig.imageHeight
        }
        detail.referSizeLayerGroupList.map((g) => {
          const { referSizeLayerGroupImageList } = g
          referSizeLayerGroupImageList.some((item) => {
            if (item.type === MAKER) {
              g.width = item.width
              g.height = item.height
              g.left = item.left
              g.top = item.top
              return true
            }
          })
        })
        this.psdInfo = detail
      }
    },

    async getPsdInfo() {
      try {
        const { code, detail } = await getKnifeReference({ id: Number(this.$route.query.id) })
        if ($SUC({ code })) {
          if (detail.length === 0) {
            this.docSrc = require('@/assets/images/default.png')
            this.$emit('updateTipMsg', '请先创建参考图，再进行校验操作！')
          }
          this.info = detail[0]
          if (this.fLoading && !this.docSrc) {
            this.docSrc = this.info && this.info.checkImagePath
            if (!this.docSrc) {
              this.loading = false
            }
          }
        }
      } catch (err) {
        console.log(err)
      }
    },

    getCoodsToCreateDom(document = {}, data = []) {
      const { height, width } = document
      const boxWidth = width >= height ? this.$refs.preview.offsetWidth : Math.min(BOXHEIGHT, height) * (width / height)
      const tmpArr = data.map((item) => {
        this.boxOption = {}
        const boxOption = this.boxOption
        if (width >= height) {
          boxOption.width = boxWidth
        }
        if (width < height) {
          boxOption.width = boxWidth
          boxOption.height = BOXHEIGHT
        }
        const coord = conversionCoord(item, document, boxOption)
        if (this.$refs.preview) {
          const offsetHeight = this.$refs.preview.offsetHeight
          coord.height = Math.min(parseFloat(coord.height), offsetHeight) + 'px'
        }
        coord.groupInfo = item
        return coord
      })
      return tmpArr
    },

    async initData() {
      try {
        await this.baseSizeInfo()
        await this.getPsdInfo()
      } catch (err) {
        console.log(err)
      }
      this.isDataLoaded = true
      this.process.request = true
    }
  }
}
</script>

<style lang="scss" scoped>
.checkProShowCanvas {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 1px #999;
  padding: 0 10px;
  height: 100%;
  padding: 5px;

  .preview-wrapper {
    padding: 5px 0;
    font-size: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .preview-wrapper.active {
    outline: 1px solid $color-danger;
  }

  .preview {
    position: relative;
    display: inline-block;

    .el-image {
      width: 100%;
      border: 1px solid $border-color;
      cursor: pointer;
    }

    .group {
      position: absolute;
      cursor: pointer;
    }

    .group.active {
      outline: 1px solid $color-danger;
    }
  }

  .auto-preview {
    width: auto;

    .el-image {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 400px;
      border: 1px solid $border-color;
      cursor: pointer;

      ::v-deep {
        .el-image__inner {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 400px;
        }
      }
    }

    .group {
      position: absolute;
      cursor: pointer;
    }
  }
}
</style>
